import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import i18n from 'i18next';
import 'date-fns';

import {
  AgilityButton,
  AgilityCard,
  AgilityTypography,
  AgilityGrid,
} from 'Src/AgilityComponents';

import {
  isNullOrEmpty,
  isValidEmail,
  isValidMobilePhoneNumber,
  validateFields,
} from 'App/utils/validationHelper';

import { scrollToSection } from 'App/utils/helper';

import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';

import YourDetailSecondaryContact from './Components/YourDetailSecondaryContact';
import {
  defaultFieldsState,
  defaultTouchedState,
  fieldValidators,
  updateYourDetailApiData,
  secondaryContactArrayField,
  defaultErrorsState,
} from './utils';
import YourDetailShowPreferContact from './Components/YourDetailShowPreferContact';
import YourDetailHearABoutUs from './Components/YourDetailHearAboutUs';
import YourDetailPrimaryContact from './Components/YourDetailPrimaryContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CHECK_PHONES_BY_API = ['primaryMobileNumber'];
const CHECK_EMAILS_BY_API = ['primaryEmail'];

const YourDetail = ({
  id,
  className,
  salutationList,
  saveProgressTrigger,
  isActive,
  signupAPIResponse,
  onNavigate,
  propertyType,
  triggerTempProgressSave,
  customConfig,
  preferHearingList,
  nextLoading,
  setProgressData,
  validatePhone,
  validateEmail,
  openProgressDialog,
  isAgentView,
}) => {
  const prevPropRef = React.useRef();

  const {
    showPreferContact,
    disableSecondaryContact,
    showHearAboutUs,
    enableExperianMobileValidation,
    enableExperianEmailValidation,
    hasMobileAuthentication,
  } = customConfig;

  const [fields, setFields] = useState(defaultFieldsState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState(defaultTouchedState);

  const [primaryDobError, setPrimaryDobError] = useState('');
  const [secondaryDobError, setSecondaryDobError] = useState('');

  const [loadingFields, setLoadingFields] = useState({
    primaryMobileNumber: false,
    primaryEmail: false,
  });

  const [doValidation, setDoValidation] = useState(false);
  const [validateApiField, setValidateApiField] = useState(null);

  let errorMessagesByApi = useRef({});

  const isResident = useMemo(() => propertyType === 'RESIDENT', [propertyType]);
  const validators = fieldValidators(customConfig, isResident);

  const updateResponse = useCallback(
    () => ({ ...signupAPIResponse, ...updateYourDetailApiData(fields) }),
    [fields, signupAPIResponse]
  );

  const isLoading = Object.values(loadingFields).some(value => value);

  const setLoadingIconOnFields = useCallback((onFields, isLoading) => {
    const loadingItems = onFields.reduce((obj, val) => {
      obj[val] = isLoading;
      return obj;
    }, {});
    setLoadingFields(prev => ({ ...prev, ...loadingItems }));
  }, []);

  const validateExperianContact = useCallback(
    (checkedKeys, checkOnlyKey, callback) => {
      if (!enableExperianMobileValidation && !enableExperianEmailValidation) {
        return callback();
      }

      let checkingFields = [],
        promises = [];
      for (const key in touched) {
        if (
          checkedKeys.includes(key) ||
          !touched[key] ||
          (!isNullOrEmpty(checkOnlyKey) && checkOnlyKey !== key)
        ) {
          continue;
        }

        if (!isNullOrEmpty(fields[key])) {
          if (
            enableExperianMobileValidation &&
            CHECK_PHONES_BY_API.includes(key)
          ) {
            checkingFields.push(key);
            promises.push(validatePhone(fields[key]));
          }
          if (
            enableExperianEmailValidation &&
            CHECK_EMAILS_BY_API.includes(key)
          ) {
            checkingFields.push(key);
            promises.push(validateEmail(fields[key]));
          }
        }
      }

      if (!isNullOrEmpty(checkingFields)) {
        setLoadingIconOnFields(checkingFields, true);
        Promise.all(promises).then(results => {
          results.forEach((item, index) => {
            item.isSuccess
              ? delete errorMessagesByApi.current[checkingFields[index]]
              : (errorMessagesByApi.current[checkingFields[index]] =
                  item.message);
          });

          setLoadingIconOnFields(checkingFields, false);
          return callback();
        });
      } else {
        return callback();
      }
    },
    [
      fields,
      touched,
      validateEmail,
      validatePhone,
      enableExperianMobileValidation,
      enableExperianEmailValidation,
      setLoadingIconOnFields,
    ]
  );

  const validateForm = useCallback(
    (validateOptionalPhones = false) => {
      const formValidationData = validateFields(validators, fields, touched);
      const errorMessages = formValidationData.errorMessages;
      let invalidField = formValidationData.invalidField;

      if (
        validateOptionalPhones &&
        fields.hasAdditionalContacts &&
        !fields.secondaryMobileNumber &&
        !fields.secondaryHomeNumber
      ) {
        errorMessages.secondaryMobileNumber = i18n.t(
          'yourDetail.placeholder.mobilePhoneHelperText'
        );

        invalidField = 'secondaryMobileNumber';
      }

      setErrors(errorMessages);
      return { errorMessages, invalidField };
    },
    [fields, touched, validators]
  );

  let handleChangeTimer = useRef(0);

  const handleChange = (val, name) => {
    if (name === 'hasAdditionalContacts') {
      val = val === 'true';
    }
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));

    setFields(prev => ({
      ...prev,
      [name]: val,
    }));
    setDoValidation(true);
    if (
      CHECK_PHONES_BY_API.includes(name) ||
      CHECK_EMAILS_BY_API.includes(name)
    ) {
      setValidateApiField(name);
    }
  };

  useEffect(() => {
    if (doValidation) {
      const { errorMessages } = validateForm();
      if (validateApiField) {
        clearTimeout(handleChangeTimer.current);
        handleChangeTimer.current = setTimeout(() => {
          validateExperianContact(
            Object.keys(errorMessages),
            validateApiField,
            () => {
              setErrors({ ...errorMessages, ...errorMessagesByApi.current });
            }
          );
        }, 500);
        setValidateApiField(null);
      }

      setErrors({ ...errorMessages, ...errorMessagesByApi.current });
      setDoValidation(false);
    }
  }, [doValidation, validateApiField]);

  const callNextStep = React.useCallback(() => {
    if (isLoading) {
      return;
    }
    const data = updateResponse();
    if (isAgentView || !hasMobileAuthentication) {
      data.currentPage = signupSteps.yourProperty;
    } else {
      data.currentPage = signupSteps.mobileAuthentication;
    }

    onNavigate('next', data, false);
  }, [hasMobileAuthentication, isAgentView, onNavigate, updateResponse]);

  const validateCurrentForm = useCallback(
    e => {
      const resetTouched = { ...touched };
      const resetFields = { ...fields };
      for (const key in touched) {
        if (
          fields['hasAdditionalContacts'] !== true &&
          secondaryContactArrayField.includes(key)
        ) {
          resetFields[key] = '';
          resetTouched[key] = false;
        } else {
          if (fields[key] && typeof fields[key] === 'string') {
            resetFields[key] = fields[key].trim();
          }
          resetTouched[key] = true;
        }
      }

      setTouched(resetTouched);
      setFields(resetFields);

      const { errorMessages, invalidField } = validateForm(true);
      validateExperianContact(Object.keys(errorMessages), null, () => {
        const invalidFieldByApi = Object.keys(errorMessagesByApi.current)[0];
        if (
          !invalidField &&
          !primaryDobError &&
          !secondaryDobError &&
          !invalidFieldByApi
        ) {
          callNextStep();
        } else {
          setErrors({ ...errorMessages, ...errorMessagesByApi.current });
          scrollToSection(invalidField || invalidFieldByApi);
        }
      });
    },
    [
      touched,
      fields,
      validateForm,
      primaryDobError,
      secondaryDobError,
      callNextStep,
      validateExperianContact,
    ]
  );

  const resetValidation = useCallback(() => {
    setErrors(defaultErrorsState);
    setTouched(defaultTouchedState);
  }, []);

  const onBackClick = useCallback(() => {
    resetValidation();
    onNavigate('back');
  }, [onNavigate, resetValidation]);

  // Hydrate form fields with API contact data
  const setContactFields = useCallback(
    (fieldData, contactData, isPrimary = true) => {
      if (!contactData) {
        return fieldData;
      }

      const type = isPrimary ? 'primary' : 'secondary';

      const mobilePhone = contactData.phones.find(p => p.type === 'Mobile');
      const homePhone = contactData.phones.find(p => p.type === 'Landline');

      if (mobilePhone) {
        fieldData[`${type}MobileNumber`] = mobilePhone.phoneNumber;
        fieldData[`${type}MobileNumberId`] = mobilePhone.id;
      }

      if (homePhone) {
        fieldData[`${type}HomeNumber`] = homePhone.phoneNumber;
        fieldData[`${type}HomeNumberId`] = homePhone.id;
      }

      if (isPrimary && showPreferContact) {
        fieldData['contactPreference'] = contactData['contactPreference'];
      }

      return {
        ...fieldData,
        [`${type}Id`]: contactData['id'],
        [`${type}Title`]: contactData['title'],
        [`${type}FirstName`]: contactData['firstName'],
        [`${type}MiddleName`]: contactData['middleName'],
        [`${type}LastName`]: contactData['lastName'],
        [`${type}Dob`]: contactData['dateOfBirth'],
        [`${type}Email`]: contactData['emailAddress'],
        [`${type}EmailId`]: contactData['emailId'],
      };
    },
    [showPreferContact]
  );

  // Hydrate Form with fresh data from API
  useEffect(() => {
    if (!signupAPIResponse) {
      return;
    }

    const { referralSource, hasAdditionalContacts, contacts } =
      signupAPIResponse;

    let fieldData = {
      hearAboutUs: referralSource,
      hasAdditionalContacts: hasAdditionalContacts,
    };

    fieldData = setContactFields(fieldData, contacts[0], true);
    fieldData = setContactFields(fieldData, contacts[1], false);

    setFields(fieldData);
  }, [setContactFields, signupAPIResponse]);

  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = updateResponse();
      data.currentPage = signupSteps.yourDetails;
      onNavigate('progress', data, true);
    }
    if (isActive === false) {
      resetValidation();
    }
    prevPropRef.current = saveProgressTrigger;
  }, [
    saveProgressTrigger,
    isActive,
    fields,
    onNavigate,
    signupAPIResponse,
    updateResponse,
    resetValidation,
  ]);

  useEffect(() => {
    if (!triggerTempProgressSave) {
      return;
    }

    if (
      triggerTempProgressSave.type === 'email' &&
      isValidEmail(fields.primaryEmail)
    ) {
      setProgressData({
        value: fields.primaryEmail,
        name: fields.primaryFirstName,
      });
    } else if (
      triggerTempProgressSave.type === 'mobile' &&
      isValidMobilePhoneNumber(fields.primaryMobileNumber)
    ) {
      setProgressData({
        value: fields.primaryMobileNumber,
        name: fields.primaryFirstName,
      });
    }
  }, [
    fields.primaryEmail,
    fields.primaryFirstName,
    fields.primaryMobileNumber,
    setProgressData,
    triggerTempProgressSave,
  ]);

  return (
    <AgilityCard className={`steps-wrapper ${className}`} id={id}>
      <AgilityTypography variant="h4" component="h4" className="mb-2">
        {i18n.t('yourDetail.header.text')}
      </AgilityTypography>
      <form autoComplete="off" noValidate data-test-id="yourDetailForm">
        <AgilityGrid container spacing={2}>
          <YourDetailPrimaryContact
            {...{
              isActive,
              handleChange,
              salutationList,
              dobError: primaryDobError,
              onDobError: setPrimaryDobError,
              fields,
              errors,
              loadingFields,
            }}
          />

          {showPreferContact && (
            <YourDetailShowPreferContact
              {...{ fields, errors, isActive, handleChange }}
            />
          )}

          {!disableSecondaryContact && (
            <YourDetailSecondaryContact
              {...{
                isActive,
                handleChange,
                salutationList,
                dobError: secondaryDobError,
                onDobError: setSecondaryDobError,
                fields,
                errors,
              }}
            />
          )}

          {showHearAboutUs && (
            <YourDetailHearABoutUs
              {...{
                preferHearingList,
                handleChange,
                errors,
                fields,
                isActive,
              }}
              data-test-id="hear-about-us-option"
            />
          )}
        </AgilityGrid>
        <AgilityGrid container spacing={2} justifyContent="flex-end">
          <AgilityGrid item xs={12} sm={12}>
            <div className="steps-footer">
              {isAgentView && (
                <AgilityButton
                  disabled={!isActive}
                  color="primary"
                  onClick={openProgressDialog}
                  label={i18n.t('saveprogress.sms.button')}
                  data-test-id="smsButton"
                  endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                />
              )}
              <AgilityButton
                color="primary"
                onClick={onBackClick}
                disabled={!isActive}
                label={i18n.t('signup.button.back')}
                data-test-id="backButton"
                className="push"
              />
              <AgilityButton
                variant="contained"
                color="primary"
                type="primary"
                label={i18n.t('signup.button.next')}
                disabled={!isActive || isLoading}
                data-test-id="nextButton"
                onClick={validateCurrentForm}
                loading={nextLoading}
              />
            </div>
          </AgilityGrid>
        </AgilityGrid>
      </form>
    </AgilityCard>
  );
};

export default YourDetail;
